import * as Blockly from "blockly/core"

export const blocks = [
    {
        type: "SSD1306_clear",
        message0: "Clear LCD display",
        previousStatement: true,
        nextStatement: true,
        style: "ezDisplay_blocks",
        tooltip: "Clear the displayed buffer.",
        helpUrl: "https://github.com/datacute/Tiny4kOLED",
    },
    {
        type: "SSD1306_print",
        message0: "Print %1 to LCD",
        args0: [
            {
                type: "input_value",
                name: "CONTENT",
                check: ["Number", "String"],
            },
        ],
        previousStatement: true,
        nextStatement: true,
        style: "ezDisplay_blocks",
        tooltip: "Write text to the working buffer.",
        helpUrl: "https://github.com/datacute/Tiny4kOLED",
    },
    {
        type: "SSD1306_font",
        message0: "%1",
        args0: [
            {
                type: "field_dropdown",
                name: "FONT",
                options: [
                    ["Default font (6x8)", "FONT8X16"],
                    ["Default font (8x16)", "FONT6X8"],
                    ["11x16 font", "FONT11X16"],
                    ["5x5 font", "FONT5X5"],
                    ["7 segment style", "FONT7LINEDIGITAL"],
                    ["Acme 5 outlines", "FONTACME5OUTLINES"],
                    ["Atari 8x16", "FONT8X16ATARI"],
                    ["Aztech", "FONTAZTECH"],
                    ["Blokus", "FONTBLOKUS"],
                    ["BM Plain", "FONTBMPLAIN"],
                    ["BMSPA", "FONTBMSPA"],
                    ["Boxes (for drawing outlines)", "&cp_437_box_drawing_font"],
                    ["Bubbles Standard", "FONTBUBBLESSTANDARD"],
                    ["Commo Monospaced", "FONTCOMMOMONOSPACED"],
                    ["Crackers", "FONTCRACKERS"],
                    ["Formplex 12pt", "FONTFORMPLEX12"],
                    ["Giant digits (16x32)", "FONT16X32DIGITS"],
                    ["Haiku", "FONTHAIKU"],
                    ["HISKYF21", "FONTHISKYF21"],
                    ["Homespun", "FONTHOMESPUN"],
                    ["HUNTER", "FONTHUNTER"],
                    ["m38", "FONTM38"],
                    ["Minimum (outline)", "FONTMINIMUM1"],
                    ["Minimum", "FONTMINIMUM"],
                    ["Modern DOS (8x16)", "FONT8X16MDOS"],
                    ["Modern DOS (8x8)", "FONT8X8MDOS"],
                    ["Pixel Operator Bold", "FONT8X16POB"],
                    ["Pixel Operator", "FONT8X16PO"],
                    ["pzim (3x5)", "FONTPZIM3X5"],
                    ["Raumsond", "FONTRAUMSOND"],
                    ["Renew", "FONTRENEW"],
                    ["Simplified Chinese 16x16", "&ssd1306xled_font16x16cn"], // will the different locale (GBK) work with utf-8?
                    ["Sloth", "FONTSLOTH"],
                    ["SUPERDIG", "FONTSUPERDIG"],
                    ["Tama Mini 02", "FONTTAMAMINI02"],
                    ["zxpix", "FONTZXPIX"],
                ],
            },
        ],
        output: "DCFont",
        style: "ezDisplay_blocks",
        tooltip: "A font on the ezDisplay.",
        helpUrl: "https://github.com/datacute/Tiny4kOLED",
    },
    {
        type: "SSD1306_scroll",
        message0: "Scroll text %1 %2",
        args0: [
            {
                type: "input_value",
                name: "CONTENT",
                check: ["Number", "String"],
            },
            {
                type: "field_dropdown",
                name: "DIRECTION",
                options: [
                    ["LEFT", "oled.scrollContentLeft(1, 2, 5, 122);\ndelay(10);\noled.setCursor(122, 1);\noled.clipText(nextRowOfTextToDraw++, 1, textToScroll);"],
                    ["RIGHT", "oled.scrollContentRight(1, 2, 5, 122);\ndelay(10);\noled.setCursor(5, 1);\noled.clipText(nextRowOfTextToDraw--, 1, textToScroll);"],
                ],
            },
        ],
        previousStatement: true,
        nextStatement: true,
        style: "ezDisplay_blocks",
        tooltip: "Scroll text in a direction.",
        helpUrl: "https://github.com/datacute/Tiny4kOLED",
    },
    {
        type: "SSD1306_set_font",
        message0: "Set the LCD font to %1",
        args0: [
            {
                type: "input_value",
                name: "FONT",
                check: "DCFont",
            },

        ],
        previousStatement: true,
        nextStatement: true,
        style: "ezDisplay_blocks",
        tooltip: "Set the font used for printing text.",
        helpUrl: "https://github.com/datacute/Tiny4kOLED",
    },
    {
        type: "SSD1306_set_cursor",
        message0: "Move LCD cursor to (%1, %2) \u00d7 8",
        args0: [
            {
                type: "input_value",
                name: "X",
                check: "Number",
            },
            {
                type: "input_value",
                name: "Y",
                check: "Number",
            },
        ],
        previousStatement: true,
        nextStatement: true,
        style: "ezDisplay_blocks",
        tooltip: "Move the point from which drawing will occur.",
        helpUrl: "https://github.com/datacute/Tiny4kOLED",
    },
    {
        type: "SSD1306_print_image",
        message0: "Draw image data %1 at (%2, %3) \u00d7 8 with size (%4, %5) \u00d7 8",
        args0: [
            {
                type: "input_value",
                name: "CONTENT",
                check: "String",
            },
            {
                type: "input_value",
                name: "X",
                check: "Number",
            },
            {
                type: "input_value",
                name: "Y",
                check: "Number",
            },
            {
                type: "input_value",
                name: "W",
                check: "Number",
            },
            {
                type: "input_value",
                name: "H",
                check: "Number",
            },
        ],
        previousStatement: true,
        nextStatement: true,
        style: "ezDisplay_blocks",
        tooltip: "Upload image hex to display on screen and set image location and size.",
        helpUrl: "https://github.com/datacute/Tiny4kOLED",
    },
    {
        type: "SSD1306_framebuffer_switch_working",
        message0: "Switch working buffer",
        previousStatement: true,
        nextStatement: true,
        style: "ezDisplay_blocks",
        tooltip: "Switch the working buffer pointer, so it now points to the other buffer.",
        helpUrl: "https://github.com/datacute/Tiny4kOLED",
    },
    {
        type: "SSD1306_framebuffer_switch_displayed",
        message0: "Switch displayed buffer",
        previousStatement: true,
        nextStatement: true,
        style: "ezDisplay_blocks",
        tooltip: "Switch the displayed buffer pointer, so it now points to the other buffer.",
        helpUrl: "https://github.com/datacute/Tiny4kOLED",
    },
    {
        type: "SSD1306_framebuffer_switch_both",
        message0: "Swap rendered and displayed buffers",
        previousStatement: true,
        nextStatement: true,
        style: "ezDisplay_blocks",
        tooltip: "Swap the two pointers, so the back buffer becomes the front buffer and vice versa.",
        helpUrl: "https://github.com/datacute/Tiny4kOLED",
    },
]

Blockly.defineBlocksWithJsonArray(blocks)
